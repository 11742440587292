
import { computed } from "vue";
import { useBlock } from "@libraryComposables/useBlock";
import { isObject as _isObject } from "lodash";

export default {
	name: "CoreBlockGrid",
	props: {
		index: {
			type: Number,
			default: 0,
		},
		nestedLevel: {
			type: Number,
			default: 0,
		},
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {
			blockChildren,
			blockChildrenCount,
			blockVariantLayout,
			bodyClasses,
			childBlockComponent,
			hasChildren,
			nestedLevelNext,
		} = useBlock(props);

		const blockId = computed(() => {
			return props.settings.id;
		});

		const blockBackgroundImage = computed(() => {
			const imgSrc = _isObject(props.settings.image)
				? props.settings.image.src
				: props.settings.image;
			const bgPosition = _isObject(props.settings.image)
				? props.settings.image?.focal?.bgPosition
				: "center";
			const bgStyle = {
				"background-image": `url('${imgSrc}')`,
				"background-position": bgPosition,
				"padding-top": "12rem",
			};
			return props.settings.image ? bgStyle : "";
		});

		const sectionMainClasses = computed(() => {
			const fillSpace = props.settings["fill-space"];
			const classList = [
				"block__section_main",
				fillSpace
					? "block__section_main-fill-space"
					: "block__section_main-fill-container",
			];
			let columnCount = props.settings["column-count"];
			if (isNaN(columnCount)) {
				columnCount = 2;
			} else {
				if (columnCount < 2) {
					columnCount = 2;
				}
				if (columnCount > 6) {
					columnCount = 6;
				}
			}
			classList.push(
				`block__section_main--${columnCount}_column${
					fillSpace ? "_fill-space" : ""
				}`
			);
			return classList;
		});

		return {
			blockBackgroundImage,
			blockChildren,
			blockChildrenCount,
			blockId,
			blockVariantLayout,
			bodyClasses,
			childBlockComponent,
			hasChildren,
			nestedLevelNext,
			sectionMainClasses,
		};
	},
};
